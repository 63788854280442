import yup from '../../../components/form/CustomYupValidator';
import {
  currentDate,
  dateFormatter,
  getConvertedMoment,
  newMoment,
} from '../../../services/dateFormatUtils';

const t = (key, params = {}) =>
  I18n.t(`radiation_safety.rad_purchases.new_edit.validations.${key}`, params);

export const validationSchema = yup.object().shape({
  rad_permit_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  rad_user_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  rad_license_item_assignment_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  purchased_activity: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  purchased_quantity: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  rad_purchase_status_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  date_calibration: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  po_date: yup
    .string()
    .nullable()
    .test(
      'po_date_cannot_be_later_than_current_date',
      t('po_date_cannot_be_later_than_current_date', {
        current_date: dateFormatter.date(currentDate()),
      }),
      function _(po_date) {
        return po_date ? getConvertedMoment(po_date, true).isSameOrBefore(newMoment()) : true;
      },
    ),
  date_received: yup
    .string()
    .nullable()
    .test(
      'date_received_cannot_be_later_than_current_date',
      t('date_received_cannot_be_later_than_current_date', {
        current_date: dateFormatter.date(currentDate()),
      }),
      function _(date_received) {
        return date_received
          ? getConvertedMoment(date_received, true).isSameOrBefore(newMoment())
          : true;
      },
    ),
});
