import React from 'react';
import styled from 'styled-components';
import { DarkLink } from '../links/DarkLink';
import { showBackendErrorMessage, showSuccessMessage } from '../../services/utils';

const HeaderIcon = styled.span`
  margin-top: -2px;
`;

export const XlsxReportLink = props => {
  const { filters, xlsxReportApi, xlsxRemoteRequest, xlsxReportProps = {} } = props;

  return (
    <DarkLink
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={async () => {
        const reportParams = { ...xlsxReportProps, filterrific: filters };

        if (xlsxRemoteRequest) {
          const { ok } = await xlsxReportApi(reportParams);
          if (ok) {
            showSuccessMessage(I18n.t('form.header.flash.success.export_to_xlsx'));
          } else {
            showBackendErrorMessage(I18n.t('form.header.flash.error.export_to_xlsx'));
          }
        } else {
          window.open(xlsxReportApi(reportParams), '_blank');
        }
      }}
    >
      {I18n.t('form.header.export_to')}
      &nbsp;
      <HeaderIcon className="icon-excel report-icon" />
    </DarkLink>
  );
};
