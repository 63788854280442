import React, { useCallback, useMemo, useState, useEffect } from 'react';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';
import { EmptyListPlaceholder } from './EmptyListPlaceholder';
import { SimpleListTitle } from './SimpleListTItle';
import { SimpleListHeader } from './SimpleListHeader';
import { isDefined } from '../../services/utils';
import { SimpleListRow } from './SimpleListRow';
import { fontSize, fontWeights } from '../../assets/styles/typography';
import { Sortable } from '../other/sortable';

const maximumElementsCountForSortable = 100;

export const Cell = styled.div`
  margin-top: 0.75rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
  padding-right: 10px;
  font-size: ${fontSize.textSm};
  overflow: hidden;
  word-break: break-word;
`;

export const CellValue = styled.div`
  font-weight: ${fontWeights.headings};
  font-size: 16px;
`;

const renderCellComponent = (listRowKeyValue, columnData) => {
  if (React.isValidElement(columnData)) {
    return React.cloneElement(columnData, { listRowKeyValue });
  }
  return columnData;
};

const produceRenderRow = (rowHrefs, rowsSelectable, resourceName) => ({
  id,
  draggableIcon,
  data: { data },
  index,
}) => {
  const keyValue = `${resourceName || 'simple'}-list-row[${id}]`;
  const rowHref = rowsSelectable ? rowHrefs[index] : null;

  const mapRowData = () =>
    data.map((columnData, columnDataIndex) => {
      if (Array.isArray(columnData)) {
        const cellKeyValue = `${keyValue}-${kebabCase(columnData[0])}`;
        return (
          <Cell id={cellKeyValue} key={cellKeyValue}>
            <div>{columnData[0]}</div>
            <CellValue className={`${isDefined(columnData[1]) ? '' : 'text-muted'}`}>
              {isDefined(columnData[1]) ? columnData[1] : I18n.t('blank_value')}
            </CellValue>
          </Cell>
        );
      }
      if (typeof columnData === 'string') {
        return (
          <span id={`${keyValue}[${columnDataIndex}]`} key={columnData}>
            {columnData}
          </span>
        );
      }
      return renderCellComponent(keyValue, columnData);
    });

  return (
    <SimpleListRow
      key={keyValue}
      keyValue={keyValue}
      href={rowHref}
      draggableIcon={draggableIcon}
      index={index}
    >
      {mapRowData()}
    </SimpleListRow>
  );
};

export const SimpleList = ({
  title,
  rowHrefs,
  data,
  ids,
  resourceName,
  isSortable: isSortableProp,
  reorderItemApi,
  reorderItemApiAdditionalParams,
  idKey = 'id',
  onReorderItems = () => {},
}) => {
  // necessary for big collection - to avoid erroneous display of EmptyListPlaceholder
  const [ready, setReady] = useState(false);
  const mapDataToItems = useCallback(
    () =>
      data.map((e, index) => {
        const id = `${ids && ids.length > 0 ? ids[index] : index}`;
        return { id, data: e };
      }),
    [data],
  );
  const [items, setItems] = useState([]);
  useEffect(() => {
    setReady(false);
    setItems(mapDataToItems());
    setReady(true);
  }, [data]);

  const elementsCount = useMemo(() => (Array.isArray(data) ? data.length : 0), [data]);
  const isSortable = useMemo(
    () => elementsCount < maximumElementsCountForSortable && isSortableProp,
    [isSortableProp, elementsCount],
  );

  const empty = !data || data.length === 0;
  const rowsSelectable = !empty && rowHrefs && rowHrefs.length === data.length;
  const renderRow = useCallback(produceRenderRow(rowHrefs, rowsSelectable, resourceName), [
    rowHrefs,
    rowsSelectable,
    resourceName,
  ]);

  return (
    <>
      {title && (
        <SimpleListHeader>
          <SimpleListTitle>{title}</SimpleListTitle>
        </SimpleListHeader>
      )}
      <div>
        {ready && empty && <EmptyListPlaceholder />}
        {!empty && (
          <Sortable
            data={items}
            disableSorting={!isSortable}
            idKey={idKey}
            renderRow={renderRow}
            onReorderItems={onReorderItems}
            reorderItemApi={reorderItemApi}
            reorderItemApiAdditionalParams={reorderItemApiAdditionalParams}
          />
        )}
      </div>
    </>
  );
};
