import yup from '../../../components/form/CustomYupValidator';
import { transformNaNValue } from '../../../../services/formHelper';

export const validationSchema = yup.object().shape({
  chemical_definition_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  amount: yup
    .number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  container_count: yup
    .number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  um_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
