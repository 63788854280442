import React from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';
import { isDefined } from '../../../services/utils';
import { API } from '../../../services/api';

const t = key =>
  I18n.t(`chemical_safety.chemical_libraries.chemical_definitions.new_edit.tabs.chemicals.${key}`);

export const ChemicalsTable = props => {
  const {
    values: { id: chemicalDefinitionId },
  } = props;

  return (
    <FormSection>
      <IndexTableDefaultHandler
        title={t('title')}
        forceRenderHeader
        api={API.chemicalSafety.chemicals().index}
        additionalApiParams={{ chemicals_resource_context: 'chemical_definition_context' }}
        fixedFilters={{
          with_chemical_definition_id: chemicalDefinitionId,
        }}
        whitelistedColumns
        idToHrefFunction={_id => `/chemical_safety/chemicals/${_id}/edit`}
        forceEmpty={!isDefined(chemicalDefinitionId)}
        xlsxReportApi={API.chemicalSafety.chemicals().exportToXlsxReport}
        xlsxReportProps={{ chemical_definition_id: chemicalDefinitionId }}
        xlsxRemoteRequest
      />
    </FormSection>
  );
};
