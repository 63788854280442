import React from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { API } from '../../../services/api';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { BrandButton } from '../../../components/buttons/BrandButton';

const t = key => I18n.t(`inventory.permits.lab_permits.new_edit.${key}`);

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser,
    values: { id },
  } = props;
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;

  return (
    <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
      <FormFooterLeftPart>
        <DeletionLink
          withIcon
          hidden={!(id && userIsAdmin)}
          modelName={I18n.t('activerecord.models.permits_fdny')}
          onSubmit={async () => {
            const { ok, data } = await API.permits.destroy(id);
            if (ok) {
              setInitialFlashMessageForNextPage(t('flash.success.remove'));
              window.location.href = `/inventory/permits/lab_permits`;
            } else {
              showBackendErrorMessage(t('flash.error.remove'), data);
            }
          }}
        />
      </FormFooterLeftPart>
      <FormFooterRightPart>
        <BrandButton
          onClick={async () => {
            await defaultHandleSubmit(setFieldValue, handleSubmit);
          }}
        >
          {t('footer.save')}
        </BrandButton>
      </FormFooterRightPart>
    </FormFooter>
  );
};
