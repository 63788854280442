import { withFormik } from 'formik';
import {
  isDefined,
  prepareCustomFieldsAnswers,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { validationSchema } from './validationSchema';
import { Form } from './Form';
import { API } from '../../../services/api';

const t = key => I18n.t(`inventory.permits.lab_permits.new_edit.${key}`);

const PermitFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const permit = PermitFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(permit.id)) {
      PermitFunctions.create(permit, formikProps, values.afterSave);
    } else {
      PermitFunctions.update(permit, formikProps, values.afterSave);
    }
  },
  create: async (permit, formikProps, afterSave) => {
    const { ok, data } = await API.permits.create({ permit });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/inventory/permits/lab_permits/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.inventory.permits.lab_permits.edit.title'));
      formikProps.resetForm({
        values: PermitFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (permit, formikProps, afterSave) => {
    const { ok, data } = await API.permits.update(permit.id, { permit });
    if (ok) {
      formikProps.resetForm({
        values: PermitFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: permit => ({
    afterSave: () => {},
    permitTypeName: permit.permit_type_name,
    permitsFdnyFieldDefinitions: permit.permits_fdny_field_definitions,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'permits_issuer_id',
    'primary_building_id',
    'primary_buildings_floor_id',
    'primary_lab_id',
    'permit_type_id',
    'permit_fdny_acct',
    'responsible_person_id',
    'permit_date_last_fdny_inspect',
    'issuance_date',
    'permit_expire_date',
    'permit_status_id',
    'renewal_process',
    'permit_comments',
    'people_group_id',
    'attachments',
    'links',
    'permits_fdny_field_answers_attributes',
  ],
  preprocessBackendValues: backendValues => ({
    ...backendValues,
    permits_fdny_field_answers_attributes: prepareCustomFieldsAnswers(
      'permits_fdny',
      backendValues.permits_fdny_field_definitions,
      backendValues.permits_fdny_field_answers_attributes,
    ),
  }),
});

export const PermitForm = withFormik({
  mapPropsToValues: props => PermitFunctions.prepareInitialValues(props.permit),
  handleSubmit: PermitFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
