import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { colors } from '../../../../assets/styles/colors';
import { fontSize, fontWeights } from '../../../../assets/styles/typography';
import { NewCategorySectionBtn } from './components/NewCategorySectionBtn';
import { CategorySectionContent } from './components/CategorySectionContent';
import { DeletionLink } from '../../../../components/links/DeletionLink';

const CategorySectionContainer = styled.div`
  background: white;
  border-radius: 4px;
  border: 1px solid ${colors.sectionSeparatorColor};
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CategorySectionHeader = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
`;

const CategorySectionTitle = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
  font-size: ${fontSize.textMd};
  font-weight: ${fontWeights.headings};
`;

const IconButton = styled.button`
  border: none;
  background: none;
  padding: 0 1rem 0 0;
  &:focus {
    border: none;
    box-shadow: none;
    outline: 0;
  }
`;

export const RemoveCategoryLink = ({ onSubmit, hidden, translation }) => (
  <DeletionLink onSubmit={onSubmit} hidden={hidden} modelName={translation('section')}>
    {translation('remove_section')}
  </DeletionLink>
);

export const ChecklistBuilder = props => {
  const {
    values: { inspectionFindingDefinitionsPerCategory },
  } = props;
  const [hoveredCategoryIndex, setHoveredCategoryIndex] = useState(null);

  const removeCategory = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  };

  const categoriesCount = useMemo(() => inspectionFindingDefinitionsPerCategory.length, [
    inspectionFindingDefinitionsPerCategory,
  ]);

  return (
    <FieldArray
      name="inspectionFindingDefinitionsPerCategory"
      render={arrayHelpers =>
        inspectionFindingDefinitionsPerCategory?.length > 0 ? (
          <>
            {inspectionFindingDefinitionsPerCategory.map((category, index) => (
              <CategorySectionContainer
                key={category.category_id}
                onMouseOver={() => {
                  setHoveredCategoryIndex(index);
                }}
                onMouseLeave={() => {
                  setHoveredCategoryIndex(null);
                }}
              >
                <CategorySectionHeader>
                  <CategorySectionTitle>{category.category_name}</CategorySectionTitle>
                  {index === hoveredCategoryIndex && index !== 0 && (
                    <IconButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.swap(index, index - 1);
                      }}
                    >
                      <i className="fas fa-arrow-up" />
                    </IconButton>
                  )}
                  {index === hoveredCategoryIndex && categoriesCount - 1 !== index && (
                    <IconButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.swap(index, index + 1);
                      }}
                    >
                      <i className="fas fa-arrow-down" />
                    </IconButton>
                  )}
                  <RemoveCategoryLink
                    onSubmit={() => {
                      removeCategory(arrayHelpers, index);
                    }}
                    hidden={index !== hoveredCategoryIndex}
                    translation={key =>
                      I18n.t(`administration.inspection_types.new_edit.checklist.${key}`)
                    }
                  />
                </CategorySectionHeader>
                <CategorySectionContent
                  category={category}
                  categoryIndex={index}
                  arrayHelpers={arrayHelpers}
                  {...props}
                />
              </CategorySectionContainer>
            ))}
            <NewCategorySectionBtn arrayHelpers={arrayHelpers} {...props} />
          </>
        ) : (
          <NewCategorySectionBtn arrayHelpers={arrayHelpers} {...props} />
        )
      }
    />
  );
};
