import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  building_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  owner_ids: yup.array().min(1, I18n.t('validations.required')),
});
