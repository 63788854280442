import React, { useEffect, useState } from 'react';
import { API } from '../../services/api';
import { isDefined } from '../../services/utils';
import { Loader } from '../../components/Loader';
import { ChemicalForm } from './Form';

export const Chemical = props => {
  const { id, chemicalInventoryId } = props;
  const [fetching, setFetching] = useState(true);
  const [chemical, setChemical] = useState({});
  useEffect(() => {
    (async () => {
      const api = API.chemicalSafety.chemicals(chemicalInventoryId)[isDefined(id) ? 'show' : 'new'];
      const { ok, data } = await api(id);
      if (ok) {
        await setChemical(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <ChemicalForm {...props} chemical={chemical} />;
};

export default Chemical;
