import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';
import { Form } from './Form';

const t = key => I18n.t(`chemical_safety.chemical_inventories.new_edit.${key}`);

const ChemicalInventoryFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const chemicalInventory = ChemicalInventoryFunctions.prepareValuesToSubmit({ ...values });
    ChemicalInventoryFunctions[isDefined(chemicalInventory.id) ? 'update' : 'create'](
      chemicalInventory,
      formikProps,
      values.submitType,
      values.afterSave,
    );
  },
  create: async (chemicalInventory, formikProps, submitType, afterSave) => {
    const { ok, data } = await API.chemicalSafety.chemicalInventories.create({
      chemical_inventory: chemicalInventory,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/chemical_safety/chemical_inventories/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(t('title.edit'));
      formikProps.resetForm({
        values: ChemicalInventoryFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (chemicalInventory, formikProps, submitType, afterSave) => {
    const { ok, data } = await API.chemicalSafety.chemicalInventories.update(chemicalInventory.id, {
      chemical_inventory: chemicalInventory,
    });
    if (ok) {
      formikProps.resetForm({
        values: ChemicalInventoryFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  auxiliaryValues: chemicalInventory => ({
    updatedAt: chemicalInventory.updated_at,
    afterSave: () => {},
  }),
  backendValuesWhitelist: [
    'id',
    'identifier',
    'name',
    'building_id',
    'buildings_floor_id',
    'lab_id',
    'location_comment',
    'update_items_location',
    'status',
    'approval_date',
    'comments',
    'owner_ids',
  ],
});

export const ChemicalInventoryForm = withFormik({
  mapPropsToValues: props =>
    ChemicalInventoryFunctions.prepareInitialValues(props.chemicalInventory),
  handleSubmit: ChemicalInventoryFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
