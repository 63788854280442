import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/styles/colors';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &:not(:first-child) {
    border-top: 1px solid ${colors.lightGray};
  }
  ${props => (props.linkInside ? '' : 'padding: 0 1.25rem 0.75rem;')}
`;

const Link = styled.a`
  padding: 0 1.25rem 0.75rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: inherit;
  width: 100%;
  &:not(:first-child) {
    border-top: 1px solid ${colors.lightGray};
  }
  &:hover {
    color: inherit;
    text-decoration: none;
    background: ${colors.hoverBackground};
    cursor: pointer;
  }
`;

const DraggableIconWrapper = styled.div`
  padding: 1rem 0.5rem 0 0;
  height: 100%;
`;

const RowIndexMarker = styled.div`
  padding: 1rem 0.8rem 0 0.5rem;
  height: 100%;
`;

const RowContent = ({ keyValue, href, children }) => {
  if (href) {
    return (
      <Link id={`${keyValue}-row-link`} href={href}>
        {children}
      </Link>
    );
  }

  return children;
};

export const SimpleListRow = ({ children, keyValue, href, draggableIcon, index }) => (
  <Wrapper id={`${keyValue}-li`} key={keyValue} linkInside={href}>
    <RowContent keyValue={keyValue} href={href}>
      {draggableIcon && (
        <>
          <DraggableIconWrapper>{draggableIcon}</DraggableIconWrapper>
          <RowIndexMarker>{index + 1}</RowIndexMarker>
        </>
      )}
      {children}
    </RowContent>
  </Wrapper>
);
