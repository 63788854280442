import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { isEmpty, isEqual, difference } from 'lodash';
import { isDefined } from '../../services/utils';
import { IndexTable } from './IndexTable';
import { FormSectionHeader } from '../form/FormSectionHeader';
import { FormSectionHeaderLink } from '../form/FormSectionHeaderLinks';
import { FormSectionTitle } from '../form/FormSectionTitle';
import { ManageColumnsLink } from './ManageColumnsLink';
import { EmptyListPlaceholder } from '../lists/EmptyListPlaceholder';
import { Loader } from '../Loader';
import { XlsxReportLink } from './XlsxReportLink';

const AdditionalLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-grow: 1;
`;

const AdditionalLinkWrapper = styled.div`
  margin: 0.3rem 1rem 0.3rem 0;
  &:last-child {
    margin-right: 0;
  }
`;

const filtersToFields = filters => Object.keys(filters).map(key => key.replace(/^with_/, ''));

export class IndexTableDefaultHandler extends PureComponent {
  constructor(props) {
    super(props);
    const {
      initialPage = 1,
      initialLimitValue = 12,
      fixedFilters = {},
      initialSortFieldIdentifier = '',
      initialSortDirection = '',
      initialSortAssociation = null,
      initialOrderBy = null,
      initialOrderAlias = null,
      forceEmpty = false,
    } = this.props;

    this.state = {
      fetching: !forceEmpty,
      filters: fixedFilters,
      fixedFilters,
      currentPage: initialPage,
      totalPages: null,
      count: null,
      totalCount: null,
      limitValue: initialLimitValue,
      sortFieldIdentifier: initialSortFieldIdentifier,
      sortDirection: initialSortDirection,
      sortAssociation: initialSortAssociation,
      orderBy: initialOrderBy,
      orderAlias: initialOrderAlias,
      collection: null,
      columnsConfig: null,
    };

    this.clearFilters.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let result = { fixedFilters: props.fixedFilters || {} };

    if (!isEqual(result.fixedFilters, state.fixedFilters))
      result = {
        ...result,
        filters: result.fixedFilters,
      };
    return result;
  }

  componentDidMount() {
    const { forceEmpty } = this.props;
    if (!forceEmpty) this.refresh();
  }

  componentDidUpdate(prevProps) {
    const { forceEmpty, fixedFilters } = this.props;

    const forceEmptyChanged = prevProps.forceEmpty !== forceEmpty;
    const fixedFiltersChanged = !isEqual(prevProps.fixedFilters, fixedFilters);

    if (!forceEmpty && (forceEmptyChanged || fixedFiltersChanged)) this.refresh();
  }

  refresh = async (newStateParams = {}) => {
    this.setState({ fetching: true });
    const { api, additionalApiParams } = this.props;
    const newState = { ...this.state, ...newStateParams };
    const {
      currentPage,
      limitValue,
      filters,
      sortFieldIdentifier,
      sortDirection,
      sortAssociation,
      orderBy,
      orderAlias,
      columnAlias,
    } = newState;
    const { ok, data } = await api({
      ...additionalApiParams,
      page: currentPage,
      limit: limitValue,
      filterrific: filters,
      sort_field: orderBy || sortFieldIdentifier,
      sort_direction: sortDirection,
      sort_association: sortAssociation,
      order_alias: orderAlias,
      column_alias: columnAlias,
    });
    if (ok) {
      this.setState({
        ...newState,
        totalPages: data.total_pages,
        count: data.count,
        totalCount: data.total_count,
        limitValue: data.limit_value,
        collection: data.collection,
        columnsConfig: data.columns_config,
        sortAssociation: data.sort_association,
        orderBy: data.order_by,
        orderAlias: data.order_alias,
        columnAlias: data.column_alias,
        fetching: false,
      });
    }
  };

  getDefaultVisibleFields = () => {
    const { columnsConfig, fixedFilters } = this.state;
    const { showAllColumns = false } = this.props;
    const fixedFiltersFields = filtersToFields(fixedFilters);

    return (columnsConfig || [])
      .filter(
        config =>
          showAllColumns || (config.visible_to_user && !fixedFiltersFields.includes(config.field)),
      )
      .map(config => config.field);
  };

  getColumnsHeadersData = () => {
    const { fixedFilters, columnsConfig, sortDirection, sortFieldIdentifier, filters } = this.state;
    const { visibleFields = this.getDefaultVisibleFields(), forceEmpty = false } = this.props;

    return forceEmpty
      ? []
      : visibleFields
          .map(field => columnsConfig.find(config => config.field === field))
          .map(config => ({
            field: config.field,
            title: config.label,
            appliedSortingDirection: config.field === sortFieldIdentifier ? sortDirection : null,
            appliedFilters: filters[`with_${config.field}`],
            filterInputType: config.filter_input_type,
            selectOptions: config.select_options,
            isFilterable:
              config.type !== 'not_filterable' && !isDefined(fixedFilters[`with_${config.field}`]),
            isSortable: config.order_by !== false,
            onSortBy: e => {
              e.preventDefault();
              e.stopPropagation();
              this.refresh({
                sortDirection:
                  config.field !== sortFieldIdentifier || sortDirection === 'asc' ? 'desc' : 'asc',
                sortFieldIdentifier: config.field,
                sortAssociation: config.sort_association,
                orderBy: config.order_by,
                orderAlias: config.order_alias,
                columnAlias: config.column_alias,
              });
            },
          }));
  };

  getData = () => {
    const { collection } = this.state;
    const {
      visibleFields = this.getDefaultVisibleFields(),
      idKeyInResponse = 'id',
      idToHrefFunction = null,
      onRowClick = () => {},
      forceEmpty = false,
    } = this.props;

    return forceEmpty
      ? []
      : (collection || []).map(rowData => ({
          key: rowData[idKeyInResponse],
          onClick: isDefined(idToHrefFunction)
            ? e => {
                e.preventDefault();
                e.stopPropagation();
                window.location.href = idToHrefFunction(rowData[idKeyInResponse], rowData);
              }
            : () => onRowClick(rowData),
          cells: visibleFields.map(field => ({
            field,
            content: rowData.fields.find(f => f.field === field).cell,
          })),
        }));
  };

  addOrderingToFilter = fieldName => {
    const { visibleFields = this.getDefaultVisibleFields() } = this.props;
    const { columnsConfig } = this.state;

    const config = visibleFields
      .map(field => columnsConfig.find(c => c.field === field))
      .filter(obj => {
        return obj.field === fieldName;
      })[0];

    this.setState({
      sortAssociation: config.sort_association,
      orderBy: config.order_by,
      orderAlias: config.order_alias,
      columnAlias: config.column_alias,
    });
  };

  clearFilters() {
    const { fixedFilters = {} } = this.state;
    this.refresh({ filters: fixedFilters, sortFieldIdentifier: '', sortDirection: '' });
  }

  render() {
    const {
      currentPage,
      count,
      totalCount,
      limitValue,
      fetching,
      totalPages,
      filters,
      fixedFilters,
    } = this.state;

    const {
      title,
      whitelistedColumns = false,
      additionalLinks = null,
      additionalColumns,
      onCheckboxesChange,
      manageColumnsModel,
      xlsxReportApi,
      xlsxReportProps = {},
      xlsxRemoteRequest = false,
      forceRenderHeader = false,
    } = this.props;

    const columnsHeadersData = this.getColumnsHeadersData();
    const data = this.getData();

    const filtersFields = filtersToFields(filters);
    const fixedFiltersFields = filtersToFields(fixedFilters);

    const onlyFixedFiltersApplied = isEmpty(difference(filtersFields, fixedFiltersFields));

    const renderHeader =
      isDefined(additionalLinks) ||
      !onlyFixedFiltersApplied ||
      manageColumnsModel ||
      forceRenderHeader;
    const indexTableHidden = isEmpty(data) && onlyFixedFiltersApplied;

    return (
      <>
        {renderHeader && (
          <FormSectionHeader>
            {title ? <FormSectionTitle>{title}</FormSectionTitle> : <></>}
            <AdditionalLinksWrapper>
              {!onlyFixedFiltersApplied && (
                <AdditionalLinkWrapper>
                  <FormSectionHeaderLink onClick={() => this.clearFilters()}>
                    <i className="fa fa-sm fa-times-circle" style={{ marginRight: '3px' }} />
                    {I18n.t('helpers.filters.clear_all')}
                  </FormSectionHeaderLink>
                </AdditionalLinkWrapper>
              )}
              {manageColumnsModel && !whitelistedColumns && (
                <AdditionalLinkWrapper>
                  <ManageColumnsLink
                    model={manageColumnsModel}
                    refreshTable={this.refresh}
                    fixedFiltersFields={fixedFiltersFields}
                  />
                </AdditionalLinkWrapper>
              )}
              {xlsxReportApi && (
                <AdditionalLinkWrapper>
                  <XlsxReportLink
                    filters={filters}
                    xlsxReportApi={xlsxReportApi}
                    xlsxRemoteRequest={xlsxRemoteRequest}
                    xlsxReportProps={xlsxReportProps}
                  />
                </AdditionalLinkWrapper>
              )}
              {(additionalLinks || []).map(link => (
                <AdditionalLinkWrapper key={link.key}>{link}</AdditionalLinkWrapper>
              ))}
            </AdditionalLinksWrapper>
          </FormSectionHeader>
        )}
        {indexTableHidden ? (
          <>{fetching ? <Loader /> : <EmptyListPlaceholder />}</>
        ) : (
          <IndexTable
            rowHover
            headersData={columnsHeadersData}
            data={data}
            currentPage={currentPage}
            totalPages={totalPages}
            count={count}
            totalCount={totalCount}
            limitValue={limitValue}
            additionalColumns={additionalColumns}
            onFiltersChange={(fieldName, fieldFilterValue) => {
              const filterName = `with_${fieldName}`;
              let newFilters = { ...filters };
              if (fieldFilterValue === null) delete newFilters[filterName];
              else newFilters = { ...newFilters, [filterName]: fieldFilterValue };
              this.addOrderingToFilter(fieldName);

              this.refresh({
                currentPage: 1,
                filters: newFilters,
              });
            }}
            onPageChange={newCurrentPage => this.refresh({ currentPage: newCurrentPage })}
            onLimitValueChange={newLimitValue =>
              this.refresh({ limitValue: newLimitValue, currentPage: 1 })
            }
            onCheckboxesChange={onCheckboxesChange}
            loading={fetching}
          />
        )}
      </>
    );
  }
}
