import React from 'react';
import { FastField, withFormik } from 'formik';
import yup from '../../../form/CustomYupValidator';
import { ModalSection } from '../../../modals/ModalSection';
import { DefaultAttributesGroup } from '../../../form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { defaultShouldUpdate } from '../../../../services/fieldUtils';
import { htmlOptionRenderer } from '../../../fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../fields/AsyncSelectorField';
import { FormFooter, FormFooterRightPart } from '../../../form/FormFooter';
import { CancellationLink } from '../../../links/CancellationLink';
import { BrandButton } from '../../../buttons/BrandButton';
import {
  defaultHandleSubmit,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { API } from '../../../../services/api';

const t = key =>
  I18n.t(`chemical_safety.chemical_inventories.new_edit.modals.move_to_another_inventory.${key}`);

const Form = props => {
  const { currentInventoryId, isSubmitting, hide, setFieldValue, handleSubmit, subtitle } = props;

  return (
    <ModalSection>
      <div style={{ paddingBottom: '2rem' }} dangerouslySetInnerHTML={{ __html: subtitle }} />
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.chemical_inventory'),
          }}
        >
          <FastField
            name="target_chemical_inventory_id"
            label={t('target_chemical_inventory_id')}
            required
            api={API.selectors.chemicalInventories.index}
            apiParams={{ without_chemical_inventory_id: currentInventoryId }}
            selectionApi={API.selectors.chemicalInventories.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {t('move')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const validationSchema = yup.object().shape({
  target_chemical_inventory_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});

export const MoveToAnotherInventoryModalContent = withFormik({
  mapPropsToValues: ({ id, ids, currentInventoryId }) => ({
    id,
    ids,
    chemical_inventory_id: currentInventoryId,
    target_chemical_inventory_id: null,
  }),
  validationSchema,
  handleSubmit: async (values, formikProps) => {
    const { hide, onSuccess, multipleMove, currentInventoryId } = formikProps.props;
    const api = multipleMove
      ? API.chemicalSafety.chemicals(currentInventoryId).multipleMoveToAnotherInventory
      : API.chemicalSafety.chemicals(currentInventoryId).moveToAnotherInventory;
    const { ok, data } = await api(values.id, values);
    if (ok) {
      formikProps.resetForm();
      hide();
      onSuccess();
      showSuccessMessage(t('flash.success.move'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.move'), data);
    }
  },
})(Form);
