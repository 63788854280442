import React, { useMemo, useState, useEffect } from 'react';
import { get } from 'lodash';
import { SortableContent } from './SortableContent';
import { NotSortableContent } from './NotSortableContent';

const maximumElementsCountForSortable = 100;

const getItems = (data, getId) => {
  return data.map(element => {
    const id = getId(element);
    return { id, data: element };
  });
};

export const Sortable = props => {
  const {
    disableSorting: disableSortingProp,
    data,
    idKey = 'id',
    getId = element => get(element, idKey),
    ...remainingProps
  } = props;

  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems(getItems(data, getId));
  }, [data, idKey]);

  const elementsCount = useMemo(() => (Array.isArray(data) ? data.length : 0), [data]);
  const disableSorting = useMemo(
    () => elementsCount > maximumElementsCountForSortable || disableSortingProp,
    [disableSortingProp, elementsCount],
  );

  const childrenProps = {
    ...remainingProps,
    items,
    setItems,
    disableSorting,
  };

  if (disableSorting) return <NotSortableContent {...childrenProps} />;
  return <SortableContent {...childrenProps} />;
};
