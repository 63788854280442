import React, { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { colors } from '../../../../Style/colors';
import { DraggableIcon } from './DraggableIcon';

export const SortableRow = props => {
  const {
    item: { id, data },
    index,
    renderRow,
  } = props;

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = useMemo(
    () => ({
      listStyleType: 'none',
      transform: CSS.Transform.toString(transform),
      transition,
      ...(isDragging
        ? {
            borderTop: '0',
            background: colors.white,
            position: 'relative',
            zIndex: '9999',
            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.15)',
          }
        : {}),
    }),
    [isDragging, transform, transition],
  );

  const draggableIcon = useMemo(
    () => <DraggableIcon ref={setActivatorNodeRef} listeners={listeners} />,
    [setActivatorNodeRef, listeners],
  );

  return (
    <li id={`li-${id}`} key={id} ref={setNodeRef} style={style} {...attributes}>
      {renderRow({ id, draggableIcon, data, index })}
    </li>
  );
};
