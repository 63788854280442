import React, { useEffect, useState } from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { TextField } from '../../../components/fields/TextField';
import { NumericField } from '../../../components/fields/NumericField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { isDefined } from '../../../services/utils';
import { NumericAndUnitFields } from '../../../components/fields/compositeFields/NumericAndUnitFields';
import { FormHeader } from '../../../components/form/FormHeader';
import { FormSection } from '../../../components/form/FormSection';
import { Footer } from './Footer';
import { TypeDetails } from './TypeDetails';
import { setCollectionTypeData } from './setCollectionTypeData';

const t = key => I18n.t(`requests.waste_collection_candidates.new_edit.${key}`);

export const Form = props => {
  const {
    setFieldValue,
    isSubmitting,
    values: { id, include_waste_types_sp },
  } = props;

  const [typeDetailsLoading, setTypeDetailsLoading] = useState(false);
  const [warnAboutTypeChange, setWarnAboutTypeChange] = useState(false);

  useEffect(() => {
    if (warnAboutTypeChange) {
      setWarnAboutTypeChange(false);
    }
  }, [isSubmitting]);

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.waste_chemical_item_candidate',
          resourceName: I18n.t('resource_names.waste_chemical_item_candidate'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField
            name="waste_collection_type_id"
            required
            api={API.selectors.wasteCollectionTypes.index}
            selectionApi={API.selectors.wasteCollectionTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            warning={warnAboutTypeChange ? t('warnings.change_type') : null}
            component={AsyncSelectorField}
            onChange={async (newSelection, onChange) => {
              setTypeDetailsLoading(true);
              if (isDefined(id)) {
                setWarnAboutTypeChange(true);
              }
              await onChange(newSelection);
              await setCollectionTypeData(newSelection, setFieldValue);
              setTypeDetailsLoading(false);
            }}
          />
          <FastField name="container_qty" required component={NumericField} />
          <FastField name="waste_chemical_name_candidate" required component={TextField} />
          <FastField
            name="responsible_person_id"
            required
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField
            name="container_size"
            required
            component={NumericAndUnitFields}
            unitName="container_size_um_id"
          />
          <FastField
            name="waste_chemical_name_id"
            required
            api={API.selectors.wasteChemicalNames.index}
            selectionApi={API.selectors.wasteChemicalNames.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            hidden={!include_waste_types_sp}
          />
        </FormSection>
        <TypeDetails loading={typeDetailsLoading} {...props} />
        <Footer {...props} />
      </DefaultAttributesGroup>
    </form>
  );
};
