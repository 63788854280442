import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncMultiSelectorField } from '../../../components/fields/AsyncMultiSelectorField';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { SelectorField } from '../../../components/fields/SelectorField';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { DateField } from '../../../components/fields/DateField';
import { TextAreaField } from '../../../components/fields/TextAreaField';
import { Footer } from './Footer';
import { ChemicalsTable } from './ChemicalsTable';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';

const t = key => I18n.t(`chemical_safety.chemical_inventories.new_edit.${key}`);

export const Form = props => {
  const {
    enums: { statusOptions },
    values: { id, from_import },
  } = props;

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/chemical_safety/chemical_inventories/${id}/audits` : ''}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.chemical_inventory',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.chemical_inventory'),
          disabled: from_import,
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField name="name" required component={TextField} />
          <FastField
            name="owner_ids"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            required
            component={AsyncMultiSelectorField}
          />
          <LocationFields {...props} />
          <FastField name="location_comment" component={TextField} />
          <FastField name="update_items_location" component={CheckBoxField} />
        </FormSection>
        <FormSection title={t('sections.inventory_status')}>
          <FastField
            name="status"
            options={mapToSelectorOptions(statusOptions, 'header', 'value')}
            component={SelectorField}
          />
          <FastField
            name="updatedAt"
            label={I18n.t('activerecord.attributes.chemical_inventory.updated_at')}
            disabled
            component={DateTimeField}
          />
          <FastField name="approval_date" component={DateField} />
          <FastField name="comments" component={TextAreaField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <ChemicalsTable {...props} />
    </form>
  );
};
