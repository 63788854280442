import React, { useRef } from 'react';
import { FormHeader } from '../../../components/form/FormHeader';
import { SimpleList } from '../../../components/lists/SimpleList';
import { FormSection } from '../../../components/form/FormSection';
import { dateFormatter } from '../../../services/dateFormatUtils';
import { Modal } from '../../../components/modals/Modal';
import { SmallLightButton } from '../../../components/buttons/SmallLightButton';
import { UpdateTrainingNeedsModal } from './UpdateTrainingNeedsModal';

const t = (key, params) => I18n.t(`inventory.people.employee_training_needs.${key}`, params);

const neverHeld = () => <span className="text-danger">{t('never_held')}</span>;

export const EmployeeTrainingNeedsList = props => {
  const {
    personId,
    personManagers,
    personLMSDetails,
    lmsTrainingsApiConfig,
    personFullName,
    employeeTrainingNeeds,
    trainingTypes,
    fetchEmployeeTrainingNeeds,
  } = props;
  const updateTrainingRequirementsModalRef = useRef(null);

  const listDataForLMSTrainingNeeds = () =>
    employeeTrainingNeeds.map(training => [
      ['', training.name],
      [t('list.completed'), training.completed ? I18n.t('true') : I18n.t('false')],
      [t('list.completion_date'), dateFormatter.date(training.completionDate) || neverHeld()],
    ]);

  const listDataForSaluteTrainingNeeds = () =>
    employeeTrainingNeeds.map(training => [
      ['', training.name],
      [
        t('list.last_training_at'),
        dateFormatter.dateTime(training.last_training_at) || neverHeld(),
      ],
      [t('list.expires_at'), dateFormatter.date(training.expires_at) || neverHeld()],
    ]);

  const UpdateTrainingRequirementsButton = () => (
    <SmallLightButton onClick={() => updateTrainingRequirementsModalRef.current.open()}>
      {t('update_training_requirements_button')}
    </SmallLightButton>
  );

  const getTrainingNeedTypeIds = () => {
    if (lmsTrainingsApiConfig.enabled) {
      return employeeTrainingNeeds.map(need => need.id);
    }
    return employeeTrainingNeeds.map(need => need.trainingType.id);
  };

  return (
    <>
      <FormHeader
        title={t('title', { person_full_name: personFullName })}
        additionalActions={[<UpdateTrainingRequirementsButton />]}
      />
      <FormSection>
        <SimpleList
          data={
            lmsTrainingsApiConfig.enabled
              ? listDataForLMSTrainingNeeds(employeeTrainingNeeds)
              : listDataForSaluteTrainingNeeds(employeeTrainingNeeds)
          }
        />
      </FormSection>
      <Modal
        title={t('update_training_requirements_modal.title')}
        ref={updateTrainingRequirementsModalRef}
      >
        {modalProps => (
          <UpdateTrainingNeedsModal
            personId={personId}
            personManagers={personManagers}
            personLMSDetails={personLMSDetails}
            lmsTrainingsApiConfig={lmsTrainingsApiConfig}
            employeeTrainingNeedTypeIds={getTrainingNeedTypeIds()}
            trainingTypes={trainingTypes}
            fetchEmployeeTrainingNeeds={fetchEmployeeTrainingNeeds}
            {...modalProps}
          />
        )}
      </Modal>
    </>
  );
};
