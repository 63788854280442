import React, { useEffect, useState } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { ChemicalInventoryForm } from './Form';

export const ChemicalInventory = props => {
  const { id } = props;
  const [fetching, setFetching] = useState(true);
  const [chemicalInventory, setChemicalInventory] = useState({});
  useEffect(() => {
    (async () => {
      const api = API.chemicalSafety.chemicalInventories[isDefined(id) ? 'show' : 'new'];
      const { ok, data } = await api(id);
      if (ok) {
        await setChemicalInventory(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <ChemicalInventoryForm {...props} chemicalInventory={chemicalInventory} />;
};

export default ChemicalInventory;
