import React from 'react';
import { FastField, withFormik } from 'formik';
import yup from '../../../components/form/CustomYupValidator';
import { ModalSection } from '../../../components/modals/ModalSection';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { NumericField } from '../../../components/fields/NumericField';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { BrandButton } from '../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { transformNaNValue } from '../../../../services/formHelper';
import { API } from '../../../services/api';

const t = key => I18n.t(`chemical_safety.chemicals.new_edit.modals.create_similar.${key}`);

const Form = props => {
  const { isSubmitting, handleSubmit, subtitle, setFieldValue, hide } = props;

  return (
    <ModalSection>
      <div style={{ paddingBottom: '2rem' }} dangerouslySetInnerHTML={{ __html: subtitle }} />
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.chemical'),
          }}
        >
          <FastField
            name="number_of_duplicates"
            label={t('number_of_duplicates')}
            required
            component={NumericField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {t('create')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const validationSchema = yup.object().shape({
  number_of_duplicates: yup
    .number()
    .transform(transformNaNValue)
    .positive(I18n.t('validations.positive')),
});

export const CreateSimilarModalContent = withFormik({
  mapPropsToValues: () => ({
    number_of_duplicates: 1,
  }),
  validationSchema,
  handleSubmit: async (values, formikProps) => {
    const { id, currentInventoryId } = formikProps.props;
    const { ok, data } = await API.chemicalSafety
      .chemicals(currentInventoryId)
      .createSimilar(id, values);
    if (ok) {
      formikProps.resetForm();
      setInitialFlashMessageForNextPage(t('flash.success.create'));
      if (values.number_of_duplicates > 1) {
        window.location.href = `/chemical_safety/chemical_inventories/${currentInventoryId}/edit`;
      } else {
        window.location.href = `/chemical_safety/chemicals/${data.data.id}/edit`;
      }
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.create'), data);
    }
  },
})(Form);
