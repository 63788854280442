import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../../assets/styles/colors';

const DraggableIconSpan = styled.div`
  &:first-child {
    font-weight: '100';
    color: ${colors.lightGray};
  }
  &:hover {
    cursor: pointer;
    &:first-child {
      color: ${colors.listButtonFontColor};
    }
  }
`;

export const DraggableIcon = forwardRef((props, ref) => {
  const { listeners } = props;

  return (
    <DraggableIconSpan {...listeners} ref={ref}>
      <i className="fas fa-bars" />
    </DraggableIconSpan>
  );
});
