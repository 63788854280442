import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';
import { Form } from './Form';

const t = key => I18n.t(`chemical_safety.chemicals.new_edit.${key}`);

const ChemicalFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const chemical = ChemicalFunctions.prepareValuesToSubmit({ ...values });
    ChemicalFunctions[isDefined(chemical.id) ? 'update' : 'create'](
      chemical,
      formikProps,
      values.submitType,
      values.afterSave,
    );
  },
  create: async (chemical, formikProps, submitType, afterSave) => {
    const { ok, data } = await API.chemicalSafety.chemicals(chemical.chemical_inventory_id).create({
      chemical,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/chemical_safety/chemicals/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(t('title.edit'));
      formikProps.resetForm({
        values: ChemicalFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (chemical, formikProps, submitType, afterSave) => {
    const { ok, data } = await API.chemicalSafety
      .chemicals(chemical.chemical_inventory_id)
      .update(chemical.id, {
        chemical,
      });
    if (ok) {
      formikProps.resetForm({
        values: ChemicalFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  auxiliaryValues: chemical => ({
    chemicalDefinition: chemical.chemical_definition,
    lockedByImportedInventory: chemical.locked_by_imported_inventory,
    chemicalInventoryName: chemical.chemical_inventory_name,
    afterSave: () => {},
  }),
  backendValuesWhitelist: [
    'id',
    'identifier',
    'chemical_inventory_id',
    'chemical_definition_id',
    'chemical_manufacturer_id',
    'manufacturer_id',
    'amount',
    'um_id',
    'container_count',
    'building_id',
    'buildings_floor_id',
    'lab_id',
    'location_comment',
    'hhop_status',
    'expiration_date',
    'comments',
    'last_printed_label_date',
    'hhop_attachments',
    'hhop_links',
    'low_stock_alert',
    'low_stock_level_for_alert',
    'expiration_alert',
    'days_before_expiration_for_alert',
  ],
});

export const ChemicalForm = withFormik({
  mapPropsToValues: props => ChemicalFunctions.prepareInitialValues(props.chemical),
  handleSubmit: ChemicalFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
