import React, { useMemo, useRef, useState, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { FastField } from 'formik';
import { API } from '../../../../../services/api';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../../components/fields/AsyncSelectorField';
import { fieldDisplayModes } from '../../../../../constants/fieldDisplayModes';
import { LinkBase } from '../../../../../components/links/auxiliary/LinkBase';
import { QuestionFieldWrapper } from './QuestionFieldWrapper';
import { NewChecklistElementBtn } from './NewChecklistElementButton';
import { Modal } from '../../../../../components/modals/Modal';
import { handleSubmitWithAfterSave, isDefined } from '../../../../../services/utils';
import { QuestionDetailsModalContent } from '../QuestionDetailsModal';
import { ConditionsExplanation } from '../QuestionDetailsModal/components/ConditionsExplanation';
import { produceShouldUpdate } from '../../../../../services/fieldUtils';
import { Sortable } from '../../../../../components/other/sortable';
import { getUuid } from '../../../../../../services/uuidHelper';

const t = key => I18n.t(`administration.inspection_types.new_edit.checklist.${key}`);

export const QuestionDetailsLink = ({ onClick, hidden }) => (
  <div className="mr-1">
    <LinkBase onClick={onClick} hidden={hidden}>
      {t('question_details')}
    </LinkBase>
  </div>
);

export const RemoveQuestionLink = ({ onClick, hidden }) => (
  <div className="ml-1">
    <LinkBase onClick={onClick} hidden={hidden} className="text-danger">
      {t('remove_question')}
    </LinkBase>
  </div>
);

export const CategorySectionContent = props => {
  const {
    category,
    categoryIndex,
    arrayHelpers,
    setFieldValue,
    handleSubmit,
    values: { anyRelatedInspections },
  } = props;

  const inspectionFindingDefinitionTypes = category.inspection_finding_definitions_types;
  const fieldNameBase = `inspectionFindingDefinitionsPerCategory[${categoryIndex}].inspection_finding_definitions_types`;
  const [hoveredQuestionIndex, setHoveredQuestionIndex] = useState(null);
  const [questionForDetailsModal, setQuestionForDetailsModal] = useState({});
  const questionDetailsModalRef = useRef(null);

  const questionIdsToExclude = useMemo(
    () =>
      inspectionFindingDefinitionTypes
        .map(_definitionType => _definitionType.inspection_finding_definition_id)
        .join(),
    [inspectionFindingDefinitionTypes],
  );

  const addQuestion = () => {
    const modifiedCategory = cloneDeep(category);
    modifiedCategory.inspection_finding_definitions_types.push({
      id: '',
      inspection_finding_definition_id: '',
      question_order: '',
      uniqueIdentifer: getUuid(),
    });
    arrayHelpers.replace(categoryIndex, modifiedCategory);
  };

  const removeQuestion = index => {
    const modifiedCategory = cloneDeep(category);
    modifiedCategory.inspection_finding_definitions_types.splice(index, 1);
    arrayHelpers.replace(categoryIndex, modifiedCategory);
  };

  const openQuestionDetailsModal = async inspectionFindingDefinitionId => {
    const afterSave = async (_, inspectionTypeAttributes) => {
      const reloadedInspectionFindingDefinitionTypes =
        inspectionTypeAttributes.inspection_finding_definitions_per_category[categoryIndex]
          .inspection_finding_definitions_types;

      const inspectionFindingDefinitionsType = reloadedInspectionFindingDefinitionTypes.find(
        definitionType =>
          definitionType.inspection_finding_definition_id === inspectionFindingDefinitionId,
      );

      const { ok, data } = await API.administration.inspectionFindingDefinitionsTypes.show(
        inspectionFindingDefinitionsType.id,
      );

      if (ok) {
        await setQuestionForDetailsModal(data.data.attributes);
        await questionDetailsModalRef.current.open();
      }
    };

    await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
  };

  const updateQuestion = (questionId, question) => {
    const modifiedCategory = cloneDeep(category);
    const modifiedQuestionIndex = modifiedCategory.inspection_finding_definitions_types.findIndex(
      definitionType => definitionType.id.toString() === questionId,
    );
    modifiedCategory.inspection_finding_definitions_types[modifiedQuestionIndex] = question;
    arrayHelpers.replace(categoryIndex, modifiedCategory);
  };

  const reorderQuestions = useCallback(
    ({ oldIndex, newIndex }) => {
      const modifiedCategory = cloneDeep(category);
      const [removed] = modifiedCategory.inspection_finding_definitions_types.splice(oldIndex, 1);
      modifiedCategory.inspection_finding_definitions_types.splice(newIndex, 0, removed);
      arrayHelpers.replace(categoryIndex, modifiedCategory);
    },
    [category, categoryIndex],
  );

  return (
    <>
      <Sortable
        data={inspectionFindingDefinitionTypes}
        getId={definitionType => definitionType.id || definitionType.uniqueIdentifer}
        onReorderItems={reorderQuestions}
        renderRow={({ data, index, draggableIcon }) => (
          <QuestionFieldWrapper
            label={
              <div className="d-flex align-items-center justify-content-end">
                {draggableIcon && index === hoveredQuestionIndex && (
                  <div className="mr-2">{draggableIcon}</div>
                )}
                {`${index + 1}.`}
              </div>
            }
            inputId={`inspection_type_question_${categoryIndex}_${index}`}
            additionalContent={
              <div className="d-flex flex-row justify-content-start align-items-center">
                <QuestionDetailsLink
                  onClick={() => openQuestionDetailsModal(data.inspection_finding_definition_id)}
                  hidden={
                    index !== hoveredQuestionIndex ||
                    !isDefined(data.inspection_finding_definition_id)
                  }
                />
                <RemoveQuestionLink
                  onClick={() => {
                    removeQuestion(index);
                  }}
                  hidden={index !== hoveredQuestionIndex}
                />
              </div>
            }
            conditionsInfo={
              data.visibility_conditions?.length > 0 && (
                <ConditionsExplanation
                  questionName={data.inspection_finding_definition_name}
                  visibilityConditions={data.visibility_conditions}
                  logicalOperatorType={data.logical_operator_type}
                />
              )
            }
            onMouseOver={() => {
              setHoveredQuestionIndex(index);
            }}
            onMouseLeave={() => {
              setHoveredQuestionIndex(null);
            }}
          >
            <FastField
              name={`${fieldNameBase}[${index}].inspection_finding_definition_id`}
              api={API.selectors.inspectionFindingDefinitions.index}
              apiParams={{
                category_id: category.category_id,
                without_ids: questionIdsToExclude,
                verbose_select_label: true,
              }}
              shouldUpdate={produceShouldUpdate(
                [],
                [],
                ['inspectionFindingDefinitionsPerCategory'],
              )}
              selectionApi={API.selectors.inspectionFindingDefinitions.show}
              optionRenderer={htmlOptionRenderer('text')}
              component={AsyncSelectorField}
              initialSelectionData={
                data.text
                  ? {
                      text: data.text,
                      id: data.inspection_finding_definition_id,
                    }
                  : null
              }
              loadSelectionFromInitialSelectionData
              displayMode={fieldDisplayModes.NO_WRAPPER}
            />
          </QuestionFieldWrapper>
        )}
      />
      {category.inspection_finding_definitions_types.every(_definition_type =>
        isDefined(_definition_type.inspection_finding_definition_id),
      ) && (
        <NewChecklistElementBtn elementType="question" addElement={addQuestion}>
          {t('new_question')}
        </NewChecklistElementBtn>
      )}
      <Modal title={t('question_details_modal.title')} ref={questionDetailsModalRef}>
        {modalProps => (
          <QuestionDetailsModalContent
            question={questionForDetailsModal}
            updateQuestion={updateQuestion}
            anyRelatedInspections={anyRelatedInspections}
            {...modalProps}
            {...props}
          />
        )}
      </Modal>
    </>
  );
};
