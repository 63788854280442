import { useEffect } from 'react';
import { some } from 'lodash';
import { isDefined } from '../../../services/utils';
import { API } from '../../../services/api';

const calcRemainingValues = async (radPermitId, radLicenseItemAssignmentId, dateCalibration) => {
  if (
    !isDefined(radPermitId) ||
    !isDefined(radLicenseItemAssignmentId) ||
    !isDefined(dateCalibration)
  ) {
    return {
      remainingActivity: '',
      remainingQuantity: '',
    };
  }

  const { ok, data } = await API.radiationSafety
    .radLicenseItemAssignments(radPermitId)
    .limits(radLicenseItemAssignmentId, { date_calibration: dateCalibration });

  if (ok) {
    const { remaining_activity, remaining_quantity } = data;

    return {
      remainingActivity: remaining_activity,
      remainingQuantity: remaining_quantity,
    };
  }

  return {
    remainingActivity: '',
    remainingQuantity: '',
  };
};

export const useRemainingValues = props => {
  const {
    setFieldValue,
    errors,
    values: { rad_license_item_assignment_id, date_calibration, rad_permit_id },
  } = props;

  const errorKeys = Object.keys(errors);

  useEffect(() => {
    const fetchAndSetRemainingValues = async () => {
      const { remainingActivity, remainingQuantity } = await calcRemainingValues(
        rad_permit_id,
        rad_license_item_assignment_id,
        date_calibration,
      );

      setFieldValue('remainingActivity', remainingActivity);
      setFieldValue('remainingQuantity', remainingQuantity);
    };

    if (
      some(['rad_license_item_assignment_id', 'date_calibration'], key => errorKeys.includes(key))
    ) {
      setFieldValue('remainingActivity', '');
      setFieldValue('remainingQuantity', '');
    } else {
      setFieldValue('fetchingRemainingValues', true);
      fetchAndSetRemainingValues();
      setFieldValue('fetchingRemainingValues', false);
    }
  }, [rad_license_item_assignment_id, date_calibration, JSON.stringify(errorKeys)]);
};
