import React, { useCallback, useRef } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableRow } from './SortableRow';
import { showSuccessMessage, showBackendErrorMessage } from '../../../services/utils';

export const SortableContent = props => {
  const {
    items,
    setItems,
    renderRow = () => {},
    reorderItemApi,
    reorderItemApiAdditionalParams = {},
    onReorderItems = () => {},
  } = props;

  const initialItems = useRef([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    async ({ active, over }) => {
      if (active.id !== over.id) {
        initialItems.current = items;
        const oldIndex = Array.prototype.findIndex.call(items, e => e.id === active.id);
        const newIndex = Array.prototype.findIndex.call(items, e => e.id === over.id);
        setItems(arrayMove(items, oldIndex, newIndex));
        if (!reorderItemApi) {
          onReorderItems({ oldIndex, newIndex });
          return;
        }

        const { ok } = await reorderItemApi(active.id, {
          new_position: newIndex + 1,
          ...reorderItemApiAdditionalParams,
        });

        if (ok) {
          onReorderItems({ oldIndex, newIndex });
          showSuccessMessage(I18n.t('sortable.success.reorder'));
        } else {
          setItems(initialItems.current);
          showBackendErrorMessage(I18n.t('sortable.error.reorder'));
        }
      }
    },
    [items, setItems, reorderItemApi, reorderItemApiAdditionalParams],
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <ul className="p-0 m-0">
          {items.map((item, index) => (
            <SortableRow key={item.id} item={item} index={index} renderRow={renderRow} />
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
};
