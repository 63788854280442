import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { ModalSection } from './ModalSection';
import { FormFooter, FormFooterRightPart } from '../form/FormFooter';
import { CancellationLink } from '../links/CancellationLink';
import { BrandButton } from '../buttons/BrandButton';
import { Loader } from '../Loader';
import { AdditionalModalContent, ModalWarning } from './ModalWarning';
import { isDefined } from '../../services/utils';
import { colors } from '../../assets/styles/colors';

const Icon = styled.i`
  font-size: 65px;
  color: ${colors.lightGray};
  margin: auto;
`;

const produceDefaultModalBody = (warning, additionalContent) => (
  <div>
    <div className="d-flex justify-center">
      <Icon className="saluteicons saluteicons-warning" />
    </div>
    <ModalWarning>{warning}</ModalWarning>
    <AdditionalModalContent>{additionalContent}</AdditionalModalContent>
  </div>
);

export const DeletionModalContent = props => {
  const {
    children,
    modalRef,
    onSubmit,
    modelName,
    additionalContent,
    confirmationButtonText: confirmationButtonTextProp,
    warning: warningProp,
    hideModalAfterSubmit,
  } = props;
  const [isDeleting, setIsDeleting] = useState(false);

  const finalWarning = useMemo(() => {
    if (isDefined(warningProp)) return warningProp;
    if (isDefined(modelName))
      return I18n.t('delete_resource_modal.warning', { model_name: modelName });
    return null;
  }, [warningProp, modelName]);
  const modalBody = useMemo(
    () =>
      isDefined(children) ? children : produceDefaultModalBody(finalWarning, additionalContent),
    [children, finalWarning, additionalContent],
  );
  const confirmationButtonText = useMemo(() => {
    if (isDefined(confirmationButtonTextProp)) return confirmationButtonTextProp;
    if (isDefined(modelName))
      return I18n.t('delete_resource_modal.confirmation', { model_name: modelName });
    return null;
  }, [confirmationButtonTextProp, modelName]);

  return (
    <ModalSection>
      {modalBody}
      <FormFooter>
        <FormFooterRightPart>
          <CancellationLink onClick={() => modalRef.current.hide()} />
          <BrandButton
            onClick={async () => {
              setIsDeleting(true);
              await onSubmit();
              setIsDeleting(false);
              if (hideModalAfterSubmit) modalRef.current.hide();
            }}
          >
            {isDeleting ? <Loader /> : confirmationButtonText}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
    </ModalSection>
  );
};
