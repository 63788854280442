import React, { useMemo, useRef } from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { API } from '../../../services/api';
import { TextField } from '../../../components/fields/TextField';
import { NumericField } from '../../../components/fields/NumericField';
import { NumericAndUnitFields } from '../../../components/fields/compositeFields/NumericAndUnitFields';
import { DateField } from '../../../components/fields/DateField';
import { FilesField } from '../../../components/fields/FilesField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { TextAreaField } from '../../../components/fields/TextAreaField';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { Footer } from './Footer';
import { SmallLightButton } from '../../../components/buttons/SmallLightButton';
import { Modal } from '../../../components/modals/Modal';
import { PrintLabelsButton } from '../../../components/other/chemicalSafety/Chemicals/PrintLabelsButton';
import { MoveToAnotherInventoryModalContent } from '../../../components/other/chemicalSafety/Chemicals/MoveToAnotherInventoryModalContent';
import { CreateSimilarModalContent } from './CreateSimilarModalContent';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';

const t = key => I18n.t(`chemical_safety.chemicals.new_edit.${key}`);
const chemicalT = key => I18n.t(`activerecord.attributes.chemical.${key}`);
const moveModalT = (key, params) =>
  I18n.t(`chemical_safety.chemicals.new_edit.modals.move_to_another_inventory.${key}`, params);
const createSimilarModalT = (key, params) =>
  I18n.t(`chemical_safety.chemicals.new_edit.modals.create_similar.${key}`, params);

export const Form = props => {
  const {
    setFieldValue,
    enums: { hhopStatusOptions },
    values: {
      id,
      chemical_inventory_id,
      chemical_definition_id,
      lockedByImportedInventory,
      chemicalInventoryName,
      chemicalDefinition,
      low_stock_alert,
      expiration_alert,
    },
  } = props;

  const moveToAnotherInventoryModalRef = useRef(null);

  const setMoveModalSubtitle = useMemo(
    () =>
      moveModalT('subtitle', {
        chemical_name: chemicalDefinition?.name,
        source_inventory_name: chemicalInventoryName,
      }),
    [chemicalInventoryName, chemicalDefinition],
  );

  const createSimilarModalRef = useRef(null);

  const setCreateSimilarModalSubtitle = useMemo(
    () =>
      createSimilarModalT('subtitle', {
        chemical_name: chemicalDefinition?.name,
      }),
    [chemicalDefinition],
  );

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/chemical_safety/chemicals/${id}/audits` : ''}
        additionalActions={[
          <SmallLightButton
            key="create_similar"
            extended
            disabled={!isDefined(id) || lockedByImportedInventory}
            onClick={() => {
              createSimilarModalRef.current.open();
            }}
          >
            {t('buttons.create_similar')}
          </SmallLightButton>,
          <SmallLightButton
            key="move_to_another_inventory"
            extended
            disabled={!isDefined(id)}
            onClick={() => {
              moveToAnotherInventoryModalRef.current.open();
            }}
          >
            {t('buttons.move_to_another_inventory')}
          </SmallLightButton>,
          <PrintLabelsButton key="print_labels" disabled={!isDefined(id)} ids={[id]} extended />,
        ]}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.chemical',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.chemical'),
          disabled: lockedByImportedInventory,
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField
            name="chemical_definition_id"
            required
            api={API.selectors.chemicalDefinitions.index}
            selectionApi={API.selectors.chemicalDefinitions.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            onChange={async (newSelection, onChange) => {
              await onChange(newSelection);
              setFieldValue('chemicalDefinition', newSelection.data.attributes);
            }}
            additionalContent={
              <DefaultLink
                hidden={!(id && chemical_definition_id)}
                href={`/chemical_safety/chemical_definitions/${chemical_definition_id}/edit`}
              >
                {t('links.view_chemical_definition')}
              </DefaultLink>
            }
          />
          <FastField
            name="chemical_manufacturer_id"
            api={API.selectors.chemicalManufacturers.index}
            apiParams={{ with_chemical_definition_id: chemical_definition_id }}
            selectionApi={API.selectors.chemicalManufacturers.show}
            optionRenderer={htmlOptionRenderer('text')}
            disabled={!isDefined(chemical_definition_id) || lockedByImportedInventory}
            isClearable
            component={AsyncSelectorField}
          />
          <FastField name="manufacturer_id" component={TextField} />
          <FastField
            name="amount"
            required
            component={NumericAndUnitFields}
            unitName="um_id"
            unitProps={{ required: true }}
            unitApiParams={{ um_type: ['mass', 'volume', 'container', 'quantity', 'other'] }}
          />
          <FastField name="container_count" required component={NumericField} />
          <FastField name="created_at" disabled component={DateField} />
          <FastField name="expiration_date" component={DateField} />
          <FastField
            name="hhop_attachments"
            hint={t('hints.hhop_description')}
            component={FilesField}
          />
          <FastField
            name="hhop_status"
            hint={t('hints.hhop_description')}
            options={mapToSelectorOptions(hhopStatusOptions, 'header', 'value')}
            component={SelectorField}
          />
          <FastField name="last_printed_label_date" disabled component={DateField} />
          <FastField name="comments" component={TextAreaField} />
        </FormSection>
        <FormSection title={t('sections.location_details')}>
          <LocationFields {...props} />
          <FastField name="location_comment" component={TextAreaField} />
        </FormSection>
        <FormSection title={t('sections.details')}>
          <FastField
            name="chemicalDefinition.compounds_cas_numbers"
            label={chemicalT('chemical_definitions_compounds_cas_no')}
            disabled
            component={TextField}
          />
          <FastField
            name="chemicalDefinition.chemical_storage_group.storage_code"
            label={chemicalT('chemical_definitions_storage_group')}
            disabled
            component={TextField}
          />
          <FastField
            name="chemicalDefinition.ghs_codes"
            label={chemicalT('chemical_definitions_ghs_codes')}
            disabled
            component={TextField}
          />
          <FastField
            name="chemicalDefinition.hhop_types"
            label={chemicalT('chemical_definitions_hhop_types')}
            disabled
            component={TextField}
          />
        </FormSection>
        <FormSection title={t('sections.alerts')} defaultChildrenAttributes={{ disabled: false }}>
          <FastField
            name="low_stock_alert"
            label={chemicalT('low_stock_alert')}
            component={CheckBoxField}
          />
          <FastField
            name="low_stock_level_for_alert"
            label={chemicalT('low_stock_level_for_alert')}
            component={NumericAndUnitFields}
            hidden={!low_stock_alert}
            positiveOnly
            unitName="um_id"
            unitProps={{ disabled: true, hidden: !low_stock_alert }}
          />
          <FastField
            name="expiration_alert"
            label={chemicalT('expiration_alert')}
            component={CheckBoxField}
          />
          <FastField
            name="days_before_expiration_for_alert"
            label={chemicalT('days_before_expiration_for_alert')}
            component={NumericField}
            hidden={!expiration_alert}
            positiveOnly
            roundPrecision={0}
          />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <Modal title={moveModalT('title')} ref={moveToAnotherInventoryModalRef}>
        {modalProps => (
          <MoveToAnotherInventoryModalContent
            {...modalProps}
            currentInventoryId={chemical_inventory_id}
            subtitle={setMoveModalSubtitle}
            onSuccess={() => {
              window.location.href = `/chemical_safety/chemicals/${id}/edit`;
            }}
            id={id}
            multipleMove={false}
          />
        )}
      </Modal>
      <Modal title={createSimilarModalT('title')} ref={createSimilarModalRef}>
        {modalProps => (
          <CreateSimilarModalContent
            {...modalProps}
            subtitle={setCreateSimilarModalSubtitle}
            id={id}
            currentInventoryId={chemical_inventory_id}
          />
        )}
      </Modal>
    </form>
  );
};
