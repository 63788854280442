import React, { useCallback } from 'react';
import { API } from '../../../../services/api';
import { showBackendErrorMessage } from '../../../../services/utils';

const t = key => I18n.t(`chemical_safety.chemical_inventories.new_edit.tabs.chemicals.${key}`);

const disabledLinkStyle = {
  pointerEvents: 'none',
  cursor: 'default',
  opacity: '0.65',
  border: 'none',
};

const Link = ({ onClick, key, children }) => (
  <button
    type="button"
    className="dropdown-item d-flex justify-content-between align-items-center"
    onClick={async () => {
      await onClick();
    }}
    key={key}
  >
    {children}
  </button>
);

export const PrintLabelsButton = ({ ids, disabled, extended }) => {
  const printLabels = useCallback(
    async size => {
      const { ok } = await API.chemicalSafety.chemicals().printLabelsPdf({ ids, size });
      if (ok) {
        window.open(`/chemical_safety/chemicals/print_labels_pdf?ids=${ids}&size=${size}`);
      } else {
        showBackendErrorMessage(t('flash.error.unexpected_error'));
      }
    },
    [ids],
  );

  const linkStyle = disabled ? { ...disabledLinkStyle } : {};

  return (
    <div className="dropdown">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={`btn btn-light btn-sm ${
          extended ? 'btn-extended' : ''
        } dropdown-toggle d-flex justify-content-between align-items-center`}
        aria-expanded="false"
        aria-haspopup="true"
        data-toggle="dropdown"
        href="#"
        role="button"
        style={linkStyle}
      >
        {t('buttons.print_labels')}
      </a>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu">
        {['small', 'large'].map(size => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            key={size}
            onClick={async () => {
              await printLabels(size);
            }}
          >
            {t(`buttons.size.${size}`)}
          </Link>
        ))}
      </div>
    </div>
  );
};
